
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() img_url: string;
  @Prop() type: string;
  @Prop() file: string;
  @Prop({default: true}) isvalid: boolean | boolean[];
  @Prop() disable_reason: any;
  @Prop({default: false}) border: boolean;
  @Prop() input_type: string;
  @Prop() ref_value?: string;
  @Prop({default: '100%'}) width: string;
  @Prop({default: false}) blink: boolean;

  get style() {
    return {
      '--width': this.width,
    };
  }

  get use_isvalid() {
    if (Array.isArray(this.isvalid)) {
      var isvalid = true;
      this.isvalid.forEach(now => {
        if (!now) {
          isvalid = false;
        }
      });
      return isvalid;
    } else {
      return this.isvalid;
    }
  }

  @Emit()
  clicked() {
    return true;
  }

  disable_error() {
    var str = [];

    if (Array.isArray(this.isvalid)) {
      this.isvalid.forEach((now, i) => {
        if (!now) {
          str = str.concat(this.disable_reason[i]);
        }
      });
    } else {
      str = this.disable_reason;
    }
    dialog.openErrorDialog({error: str});
  }
  
  @Emit()
  selected(e) {
    return e;
  }

  get refs():any {
    return this.$refs;
  }


  reset_file() {
    this.refs[this.ref_value].value = null;
  }
}
