
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonDate from "@/components/common/Date.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonDate,
      CommonSelect,
      CommonText,
    },
  })
  export default class Row extends Mixins(mixinDocument) {
    @Prop() employee;

    get options() {
      return {
        employee_lost_reason: {
          1: "離職以外の理由",
          2: "事業主の都合以外の離職",
          3: "事業主の都合による離職",
        },
        employee_lost_send: { 1: "有", 2: "無" },
        employee_abroad_consign: {
          1: "派遣・請負労働者として主として当該事業所以外で就労する場合",
          2: "上に該当しない場合",
        },
        employee_abroad_country: {
          "352": "アイスランド",
          "372": "アイルランド",
          "031": "アゼルバイジャン",
          "004": "アフガニスタン",
          "840": "アメリカ合衆国",
          "784": "アラブ首長国連邦",
          "012": "アルジェリア",
          "032": "アルゼンチン",
          "533": "アルバ",
          "008": "アルバニア",
          "051": "アルメニア",
          "660": "アンギラ",
          "024": "アンゴラ",
          "028": "アンティグア・バーブーダ",
          "020": "アンドラ",
          "887": "イエメン",
          "826": "イギリス",
          "376": "イスラエル",
          "380": "イタリア",
          "368": "イラク",
          "364": "イラン・イスラム共和国",
          "356": "インド",
          "360": "インドネシア",
          "800": "ウガンダ",
          "804": "ウクライナ",
          "860": "ウズベキスタン",
          "858": "ウルグアイ",
          "218": "エクアドル",
          "818": "エジプト",
          "233": "エストニア",
          "231": "エチオピア",
          "232": "エリトリア",
          "222": "エルサルバドル",
          "036": "オーストラリア",
          "040": "オーストリア",
          "512": "オマーン",
          "528": "オランダ",
          "288": "ガーナ",
          "132": "カーボベルデ",
          "831": "ガーンジー",
          "328": "ガイアナ",
          "398": "カザフスタン",
          "634": "カタール",
          "124": "カナダ",
          "266": "ガボン",
          "120": "カメルーン",
          "270": "ガンビア",
          "116": "カンボジア",
          "324": "ギニア",
          "624": "ギニアビサウ",
          "196": "キプロス",
          "192": "キューバ",
          "300": "ギリシャ",
          "296": "キリバス",
          "417": "キルギス",
          "320": "グアテマラ",
          "414": "クウェート",
          "184": "クック諸島",
          "308": "グレナダ",
          "191": "クロアチア",
          "404": "ケニア",
          "384": "コートジボワール",
          "188": "コスタリカ",
          "174": "コモロ",
          "170": "コロンビア",
          "178": "コンゴ共和国",
          "180": "コンゴ民主共和国",
          "682": "サウジアラビア",
          "882": "サモア",
          "678": "サントメ・プリンシペ",
          "894": "ザンビア",
          "674": "サンマリノ",
          "694": "シエラレオネ",
          "262": "ジブチ",
          "832": "ジャージー",
          "388": "ジャマイカ",
          "268": "ジョージア",
          "760": "シリア・アラブ共和国",
          "702": "シンガポール",
          "716": "ジンバブエ",
          "756": "スイス",
          "752": "スウェーデン",
          "729": "スーダン",
          "724": "スペイン",
          "740": "スリナム",
          "144": "スリランカ",
          "703": "スロバキア",
          "705": "スロベニア",
          "748": "スワジランド",
          "690": "セーシェル",
          "226": "赤道ギニア",
          "686": "セネガル",
          "688": "セルビア",
          "659": "セントクリストファー・ネイビス",
          "670": "セントビンセントおよびグレナディーン諸島",
          "662": "セントルシア",
          "706": "ソマリア",
          "090": "ソロモン諸島",
          "764": "タイ",
          "410": "大韓民国",
          "158": "台湾（台湾省/中華民国）",
          "762": "タジキスタン",
          "834": "タンザニア",
          "203": "チェコ",
          "148": "チャド",
          "140": "中央アフリカ共和国",
          "156": "中華人民共和国",
          "788": "チュニジア",
          "408": "朝鮮民主主義人民共和国",
          "152": "チリ",
          "798": "ツバル",
          "208": "デンマーク",
          "276": "ドイツ",
          "768": "トーゴ",
          "214": "ドミニカ共和国",
          "212": "ドミニカ国",
          "780": "トリニダード・トバゴ",
          "795": "トルクメニスタン",
          "792": "トルコ",
          "776": "トンガ",
          "566": "ナイジェリア",
          "520": "ナウル",
          "516": "ナミビア",
          "570": "ニウエ",
          "558": "ニカラグア",
          "562": "ニジェール",
          "732": "西サハラ",
          "554": "ニュージーランド",
          "524": "ネパール",
          "578": "ノルウェー",
          "048": "バーレーン",
          "332": "ハイチ",
          "586": "パキスタン",
          "336": "バチカン市国",
          "591": "パナマ",
          "548": "バヌアツ",
          "044": "バハマ",
          "598": "パプアニューギニア",
          "585": "パラオ",
          "600": "パラグアイ",
          "052": "バルバドス",
          "275": "パレスチナ",
          "348": "ハンガリー",
          "050": "バングラデシュ",
          "626": "東ティモール",
          "242": "フィジー",
          "608": "フィリピン",
          "246": "フィンランド",
          "064": "ブータン",
          "076": "ブラジル",
          "250": "フランス",
          "100": "ブルガリア",
          "854": "ブルキナファソ",
          "096": "ブルネイ・ダルサラーム",
          "108": "ブルンジ",
          "704": "ベトナム",
          "204": "ベナン",
          "862": "ベネズエラ・ボリバル共和国",
          "112": "ベラルーシ",
          "084": "ベリーズ",
          "604": "ペルー",
          "056": "ベルギー",
          "616": "ポーランド",
          "070": "ボスニア・ヘルツェゴビナ",
          "072": "ボツワナ",
          "068": "ボリビア多民族国",
          "620": "ポルトガル",
          "344": "香港",
          "340": "ホンジュラス",
          "584": "マーシャル諸島",
          "446": "マカオ",
          "807": "マケドニア旧ユーゴスラビア共和国",
          "450": "マダガスカル",
          "454": "マラウイ",
          "466": "マリ",
          "470": "マルタ",
          "458": "マレーシア",
          "833": "マン島",
          "583": "ミクロネシア連邦",
          "710": "南アフリカ",
          "728": "南スーダン",
          "104": "ミャンマー",
          "484": "メキシコ",
          "480": "モーリシャス",
          "478": "モーリタニア",
          "508": "モザンビーク",
          "492": "モナコ",
          "462": "モルディブ",
          "498": "モルドバ共和国",
          "504": "モロッコ",
          "496": "モンゴル",
          "499": "モンテネグロ",
          "400": "ヨルダン",
          "418": "ラオス人民民主共和国",
          "428": "ラトビア",
          "440": "リトアニア",
          "434": "リビア",
          "438": "リヒテンシュタイン",
          "430": "リベリア",
          "642": "ルーマニア",
          "442": "ルクセンブルク",
          "646": "ルワンダ",
          "426": "レソト",
          "422": "レバノン",
          "643": "ロシア連邦",
          "998": "無国籍",
          "999": "不明",
        },
        employee_abroad_stay_qualification: {
          "35": "技術・人文知識・国際業務",
          "10": "技術（※廃止済）	※1",
          "11": "人文知識・国際業務（※廃止済） ※1",
          "12": "企業内転勤",
          "09": "教育",
          "01": "教授",
          "14": "技能",
          "39": "高度専門職 1 号",
          "40": "高度専門職 2 号",
          "26": "永住者",
          "27": "日本人の配偶者等",
          "28": "永住者の配偶者等",
          "29": "定住者",
          "15": "技能実習",
          "23": "特定活動（ワーキングホリデー）",
          "24": "特定活動（EPA）",
          "36": "特定活動（建設分野）",
          "37": "特定活動（造船分野）",
          "38": "特定活動（外国人調理師）",
          "41": "特定活動（ハラール牛肉生産）",
          "42": "特定活動（製造分野）",
          "43": "特定活動（家事支援）",
          "44": "特定活動（就職活動）",
          "48": "特定活動（本邦大卒者）",
          "46": "特定活動（農業）",
          "47": "特定活動（日系四世）",
          "31": "特定活動（高度学術研究活動）",
          "32": "特定活動（高度専門・技術活動）",
          "33": "特定活動（高度経営・管理活動）",
          "34": "特定活動（高度人材外国人の就労配偶者）",
          "25": "特定活動（その他）",
          "18": "留学",
          "21": "家族滞在",
          "02": "芸術",
          "03": "宗教",
          "04": "報道",
          "05": "経営・管理",
          "06": "法律・会計業務",
          "07": "医療",
          "45": "介護",
          "08": "研究",
          "13": "興行",
          "16": "文化活動",
          "17": "短期滞在",
          "20": "研修",
          "49": "特定技能 1 号（介護）",
          "50": "特定技能 1 号（ビルクリーニング）",
          "51": "特定技能 1 号（素形材産業）",
          "52": "特定技能 1 号（産業機械製造業）",
          "53": "特定技能 1 号（電気・電子情報関連産業）",
          "54": "特定技能 1 号（建設）",
          "55": "特定技能 1 号（造船・舶用工業）",
          "56": "特定技能 1 号（自動車整備）",
          "57": "特定技能 1 号（航空）",
          "58": "特定技能 1 号（宿泊）",
          "59": "特定技能 1 号（農業）",
          "60": "特定技能 1 号（漁業）",
          "61": "特定技能 1 号（飲食料品製造業）",
          "62": "特定技能 1 号（外食業）",
          "63": "特定技能 2 号（建設）",
          "64": "特定技能 2 号（造船・舶用工業）",
          "30": "不明",
        },
      };
    }

    created() {
      this.m.setLostEmpIns({
        employee_id: this.employee.id,
        data: {
          employee_emp_ins_got_on: this.employee.emp_ins_got_on,
          employee_retired_on: this.employee.retired_on,
          employee_lost_reason: 1,
          employee_lost_reason_other: null,
          employee_lost_send: 1,
          employee_roma_name: null,
          employee_abroad_num: null,
          employee_abroad_stay_date: null,
          employee_abroad_consign: null,
          employee_abroad_country: null,
          employee_abroad_stay_qualification: null,
        },
      });
    }

    get tmp_emp_ins_lost() {
      if (this.m.emp_ins_lost[this.employee.id]) {
        return this.m.emp_ins_lost[this.employee.id];
      } else {
        return {};
      }
    }
    set tmp_emp_ins_lost(data) {
      this.m.setLostEmpIns({ employee_id: this.employee.id, data });
    }
  }
