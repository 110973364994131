
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Tab extends Vue {
  @Prop() is_active: boolean;
  @Prop({default: 'white'}) backgroundcolor: string;

  @Emit()
  active() {
    if (!this.is_active) return true;
  }

  get styles () {
    return {
      '--backgroundcolor': this.backgroundcolor,
    }
  }
}
