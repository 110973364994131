
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAggregation from '@/mixins/mixinAggregation';

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinAggregation) {
    /*
    0: '所得税集計表',
    1: '住民税集計表',
    2: '社会保険料等集計表',
    3: '店舗別人件費',
    4: '500時間達成',
    */
}
