
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAggregation from "@/mixins/mixinAggregation";
  import CommonYear from "@/components/common/Year.vue";

  @Component({
    components: {
      CommonYear,
    },
  })
  export default class Main extends Mixins(mixinAggregation) {
    get tmp_year() {
      return this.m.selected_year;
    }
    set tmp_year(val) {
      this.m.setSelectedYear(val);
    }
    /*
    0: '所得税集計表',
    1: '住民税集計表',
    2: '社会保険料等集計表',
    3: '店舗別人件費',
    4: '500時間達成',
    */
  }
