
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/employee/manager";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import DocumentSocialinsdependantRow from "@/components/document/Socialinsdependant/Row.vue";

  @Component({
    components: {
      CommonInfobutton,
      DocumentSocialinsdependantRow,
    },
  })
  export default class Table extends Mixins(mixinDocument) {
    get all_rows() {
      var row_num = 0;
      this.m.selected_employee.forEach((employee_id) => {
        if (this.dependants_hash[employee_id]) {
          row_num += this.dependants_hash[employee_id].length;
        } else {
          row_num += 1;
        }
      });
      return row_num;
    }
  }
