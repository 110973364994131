
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import CommonYear from "@/components/common/Year.vue";

  @Component({
    components: {
      CommonYear,
    },
  })
  export default class Main extends Mixins(mixinDocument) {
    get tmp_year() {
      return this.m.selected_year;
    }
    set tmp_year(val) {
      this.m.setSelectedYear(val);
    }

    get minus_min_year() {
      if (this.m.selected_document == 3 || this.m.selected_document == 11) {
        return 3;
      } else {
        return Number(util.today_obj().year) - 2022;
      }
    }

    get plus_max_year() {
      if (
        this.m.selected_document == 8 ||
        this.m.selected_document == 9 ||
        this.m.selected_document == 10 ||
        this.m.selected_document == 11 ||
        this.m.selected_document == 17
      ) {
        return 0;
      } else if (this.m.selected_document == 13 || this.m.selected_document == 14) {
        if (Number(util.today_obj().month) < 6) {
          return -1;
        } else {
          return 0;
        }
      } else if (this.m.selected_document == 23) {
        return 1;
      } else if (this.m.selected_document == 7) {
        if (Number(util.today_obj().month) >= 12) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
    /*
    0: '給与明細書',
    1: '所得税徴収高計算書',
    2: '賞与明細書',
    3: '源泉徴収票(受給者用)',
    4: '源泉徴収票(税務署提出用)',
    5: '雇用保険被保険者離職票',
    6: '賃金台帳',
    7: '扶養控除等申告書',
    8: '配偶者控除等申告書',
    9: '保険料控除申告書',
    10: '住宅借入金等特別控除申告書',
    11: '源泉徴収簿',
    12: '労働者名簿',
    13: '給与支払報告書',
    14: '法定調書合計表',
    15: '雇用契約書',
    */
  }
