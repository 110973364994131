import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import global from "./global";
import manager from "./manager";
import header from "@/vuex/header";

@Module({ dynamic: true, store, name: "aggregation_manager", namespaced: true })
class Aggregation_manager extends VuexModule {
  selected_aggregation: number | null = null;
  selected_yearmonth: string | null = null;
  selected_year: number | null = null;

  @Mutation
  public setSelectedAggregation(val: number) {
    if (this.selected_aggregation == val) {
      this.selected_aggregation = null;
    } else {
      this.selected_aggregation = val;
    }
  }
  @Mutation
  public setSelectedYearmonth(val: string) {
    this.selected_yearmonth = val;
    this.selected_year = null;
  }
  @Mutation
  public setSelectedYear(val: number) {
    this.selected_yearmonth = null;
    this.selected_year = val;
  }

  @Action({ rawError: true })
  public cancelAll() {}

  @Action({ rawError: true })
  public async download() {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    if (this.selected_aggregation == 0) {
      await util.post_document(
        "document/generate_income_tax_aggregation",
        { year: this.selected_year },
        this.selected_year + "年所得税集計表.csv",
      );
    } else if (this.selected_aggregation == 1) {
      await util.post_document(
        "document/generate_res_tax_aggregation",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "分住民税集計表.csv",
      );
    } else if (this.selected_aggregation == 2) {
      await util.post_document(
        "document/generate_social_ins_aggregation",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "分社会保険料等集計表.csv",
      );
    } else if (this.selected_aggregation == 3) {
      await util.post_document(
        "document/generate_branchly_aggregation",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "分店舗別人件費.csv",
      );
    } else if (this.selected_aggregation == 4) {
      await util.post_document(
        "document/generate_five_hundred_aggregation",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "分500h達成者.csv",
      );
    } else if (this.selected_aggregation == 5) {
      await util.post_document_proxy(
        "document/generate_submit_csvs",
        {
          yearmonth: this.selected_yearmonth,
        },
        util.yearmonth2str(this.selected_yearmonth) + "提出シフト.zip",
      );
    } else if (this.selected_aggregation == 6) {
      await util.post_document_proxy(
        "document/generate_branchly_detail_aggregation",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "支給分店舗別人件費(詳細).csv",
      );
    }
  }
}

export default getModule(Aggregation_manager);
