
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import DocumentTransferRow from "@/components/document/Transfer/Row.vue";

@Component({
  components: {
    DocumentTransferRow,
  }
})
export default class Table extends Mixins(mixinDocument) {

}
