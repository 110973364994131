
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import dialog from "@/vuex/dialog";
import DocumentHistoryBranchselector from "@/components/document/History/Branchselector.vue";
import DocumentHistoryPagerHead from "@/components/document/History/pager/Head.vue";

@Component({
  components: {
    DocumentHistoryBranchselector,
    DocumentHistoryPagerHead,
  }
})
export default class Control extends Mixins(mixinDocument) {
  
}
