
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonSelect from "@/components/common/Select.vue";
import CommonText from "@/components/common/Text.vue";
import CommonZip from "@/components/common/Zip.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonCheck from "@/components/common/Check.vue";

@Component({
  components: {
    CommonSelect,
    CommonText,
    CommonZip,
    CommonDate,
    CommonCheck,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() employee;
  @Prop() dependant;
  @Prop() dependant_count;
  @Prop() max_row;

  get options() {
    return {
      dependant_type: {1: '該当', 2: '非該当', 3: '変更'},
      dependant_relationship: {'01': '実子・養子', '02': '1以外の子', '03': '父母・養父母', '04': '義父母', '05': '弟妹', '06': '兄姉', '07': '祖父母', '08': '曽祖父母', '09': '孫', '10': 'その他'},
      dependant_get_reason: {1: '出生', 2: '離職', 3: '収入減', 4: '同居', 5: 'その他'},
      dependant_not_get_reason: {1: '死亡', 2: '就職', 3: '収入増加', 4: '75歳到達', 5: '障害認定', 6: 'その他'},
      dependant_is_abroad_special: {1: '該当', 2: '非該当'},
      dependant_abroad_special_reason: {1: '留学', 2: '同行家族', 3: '特定活動',  4: '海外婚姻', 5: 'その他'},
      dependant_abroad_not_special_reason: {1: '国内転入', 2: 'その他'},
      dependant_job: {1: '無職', 2: 'パート', 3: '年金受給者', 4: '小・中学生以下', 5: '高・大学生', 6: 'その他'},
    };
  }


  get tmp_social_ins_dependant() {
    if (this.m.social_ins_dependant[this.employee.id]) {
      return this.m.social_ins_dependant[this.employee.id];
    } else {
      return {};
    }
  }
  set tmp_social_ins_dependant(data) {
    this.m.setDependantSocialIns({employee_id: this.employee.id, data});
  }
}
