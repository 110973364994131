
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/employee/manager";
  import CommonCheck from "@/components/common/Check.vue";
  import DocumentEmployeeRow from "@/components/document/Employee/Row.vue";

  @Component({
    components: {
      CommonCheck,
      DocumentEmployeeRow,
    },
  })
  export default class Table extends Mixins(mixinDocument) {
    @Prop() valid_employee_ids?: number[];
    @Prop() is_retired?: boolean;
    @Prop() no_gray?: boolean;
    @Prop({ default: false }) is_exclude_retired?: boolean;

    created() {
      this.changeSelect(null);
    }

    get selected_document() {
      return this.m.selected_document;
    }
    get selected_year() {
      return this.m.selected_year;
    }
    get selected_report_type() {
      return this.m.selected_report_type;
    }
    get selected_siku_type() {
      return this.m.selected_siku_type;
    }

    @Watch("selected_year")
    @Watch("selected_report_type")
    @Watch("selected_siku_type")
    @Watch("selected_document")
    changeSelect(val) {
      console.log(val);
      if (this.m.selected_document == 13 || this.m.selected_document == 14) {
        this.check_all(true);
      } else {
        this.check_all(false);
      }
    }
    get select_employee_all() {
      if (this.valid_employee_ids) {
        return this.valid_employee_ids.length == this.m.selected_employee.length;
      } else if (this.is_exclude_retired) {
        return (
          this.employees.filter(
            (employee) =>
              !employee.retired_on ||
              util.date2unix(employee.retired_on) > util.date2unix(util.today()),
          ).length == this.m.selected_employee.length
        );
      } else {
        return this.employees.length == this.m.selected_employee.length;
      }
    }

    check_all(val) {
      if (val) {
        if (this.valid_employee_ids) {
          this.m.setSelectedEmployees(this.valid_employee_ids);
        } else if (this.is_exclude_retired) {
          const employees = this.employees
            .filter(
              (employee) =>
                !employee.retired_on ||
                new Date(employee.retired_on).getTime() > new Date(util.today()).getTime(),
            )
            .map((employee) => {
              return employee.id;
            });
          this.m.setSelectedEmployees(employees);
        } else {
          const employees = this.employees.map((employee) => {
            return employee.id;
          });
          this.m.setSelectedEmployees(employees);
        }
      } else {
        this.m.resetSelectedEmployee();
      }
    }
  }
