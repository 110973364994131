
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonSelect from "@/components/common/Select.vue";
import CommonCheck from "@/components/common/Check.vue";
import CommonText from "@/components/common/Text.vue";
import CommonZip from "@/components/common/Zip.vue";
import CommonDate from "@/components/common/Date.vue";

@Component({
  components: {
    CommonSelect,
    CommonCheck,
    CommonText,
    CommonZip,
    CommonDate,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() employee;

  get options() {
    return {
      is_dependant: {0: '無', 1: '有'},
      employee_kind: {1: '男', 2: '女', 3: '坑内員', 5: '男基金', 6: '女基金', 7: '坑内員基金'},
      employee_abroad_reason: {1: '海外在住', 2: '短期在留', 3: 'その他'},
    };
  }

  created() {
    this.m.setGetSocialIns({employee_id: this.employee.id, data: {
      is_dependant: 0,
      employee_kind: this.employee.sex == 0 ? 1 : 2,
      employee_is_over_seventy: false,
      employee_is_double_biz: false,
      employee_is_short: false,
      employee_is_retire: false,
      employee_is_other: null,
      employee_abroad_reason: null,
      employee_reason_other: null,
      employee_zip: null,
      employee_address: null,
      employee_address_kana: null,
      social_ins_got_on: this.employee.social_ins_got_on,
    }});
  }

  get tmp_social_ins_get() {
    if (this.m.social_ins_get[this.employee.id]) {
      return this.m.social_ins_get[this.employee.id];
    } else {
      return {};
    }
  }
  set tmp_social_ins_get(data) {
    this.m.setGetSocialIns({employee_id: this.employee.id, data});
  }
}
