
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonCheck from "@/components/common/Check.vue";

@Component({
  components: {
    CommonCheck,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() employee;

  created() {
    this.m.setBonusPay({employee_id: this.employee.id, data: {
      is_over_seventy: false,
      is_double_biz: false,
    }});
  }

  get tmp_bonus_pay() {
    if (this.m.bonus_pay[this.employee.id]) {
      return this.m.bonus_pay[this.employee.id];
    } else {
      return {};
    }
  }
  set tmp_bonus_pay(data) {
    this.m.setBonusPay({employee_id: this.employee.id, data});
  }
}
