
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAggregation from "@/mixins/mixinAggregation";
  import AggregationAggregation from "@/components/aggregation/Aggregation.vue";
  import AggregationYearmonth from "@/components/aggregation/Yearmonth.vue";
  import AggregationYear from "@/components/aggregation/Year.vue";
  import AggregationCsv from "@/components/aggregation/Csv.vue";
  import AggregationExcel from "@/components/aggregation/Excel.vue";

  @Component({
    components: {
      AggregationAggregation,
      AggregationYearmonth,
      AggregationYear,
      AggregationCsv,
      AggregationExcel,
    },
  })
  export default class Main extends Mixins(mixinAggregation) {
    get is_yearmonth() {
      return (
        this.m.selected_aggregation == 1 ||
        this.m.selected_aggregation == 2 ||
        this.m.selected_aggregation == 3 ||
        this.m.selected_aggregation == 4 ||
        this.m.selected_aggregation == 5 ||
        this.m.selected_aggregation == 6
      );
    }
    get is_year() {
      return this.m.selected_aggregation == 0;
    }
    get is_csv() {
      return (
        (this.m.selected_aggregation == 0 && this.m.selected_year !== null) ||
        ((this.m.selected_aggregation == 1 ||
          this.m.selected_aggregation == 2 ||
          this.m.selected_aggregation == 3 ||
          this.m.selected_aggregation == 4 ||
          this.m.selected_aggregation == 6) &&
          this.m.selected_yearmonth !== null)
      );
    }
    get is_excel() {
      return this.m.selected_aggregation == 5 && this.m.selected_yearmonth !== null;
    }

    get invisibleSpan() {
      if (!this.is_yearmonth && !this.is_year) return false;
      else if (this.header.plan == 2) return false;
      else {
        const today = new Date(util.formatDate(new Date(), "yyyy-mm-01"));
        let date = new Date();
        if (this.is_yearmonth) {
          if (this.m.selected_yearmonth === null) return false;
          date = new Date(
            util.formatDate(new Date(`${this.m.selected_yearmonth}-01`), "yyyy-mm-01"),
          );
        } else if (this.is_year) {
          if (this.m.selected_year === null) return false;
          date = new Date(util.formatDate(new Date(`${this.m.selected_year}-01-01`), "yyyy-01-01"));
        }
        date.setFullYear(date.getFullYear() + 3);
        return date.getTime() <= today.getTime();
      }
    }
  }
