
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() tmp_contract_document;

  delete_tmp_contract_document() {
    this.m.deleteContractDocument({contract_document_id: this.tmp_contract_document.id});
  }
  // download() {
  //   this.m.download({contract_document_id: this.tmp_contract_document.id, document_template_name: this.document_template_hash[this.m.selected_document - 100].name, employee_name: this.employee_hash_all[this.tmp_contract_document.employee_id].name});
  // }

  view_contract_document() {
    this.change_page('contract_document', {contract_document_id: this.tmp_contract_document.id});
  }
}
