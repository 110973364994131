
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import dialog from "@/vuex/dialog";
  import DocumentHistoryRow from "@/components/document/History/Row.vue";

  @Component({
    components: {
      DocumentHistoryRow,
    },
  })
  export default class Table extends Mixins(mixinDocument) {}
