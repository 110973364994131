
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAggregation from "@/mixins/mixinAggregation";
  import CommonYearmonth from "@/components/common/Yearmonth.vue";

  @Component({
    components: {
      CommonYearmonth,
    },
  })
  export default class Main extends Mixins(mixinAggregation) {
    created() {
      const date = new Date();
      this.m.setSelectedYearmonth(util.formatDate(date, "yyyy-mm"));
    }
    get tmp_yearmonth() {
      return this.m.selected_yearmonth;
    }
    set tmp_yearmonth(val) {
      this.m.setSelectedYearmonth(val);
    }
    get plus_max_year() {
      if (this.m.selected_aggregation == 5) {
        return 1;
      } else {
        return 0;
      }
    }
    /*
    0: '所得税集計表',
    1: '住民税集計表',
    2: '社会保険料等集計表',
    3: '店舗別人件費',
    4: '500時間達成',
    */
  }
