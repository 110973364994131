
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonDate from "@/components/common/Date.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonDate,
      CommonSelect,
      CommonCheck,
      CommonText,
    },
  })
  export default class Row extends Mixins(mixinDocument) {
    @Prop() employee;

    get options() {
      return {
        employee_lost_reason: {
          4: "退職等",
          5: "死亡",
          7: "75歳到達",
          9: "障害認定",
          11: "社会保障協定",
        },
        employee_card: {
          1: "1枚",
          2: "2枚",
          3: "3枚",
          4: "4枚",
          5: "5枚",
          6: "6枚",
          7: "7枚",
          8: "8枚",
          9: "9枚",
          10: "10枚",
        },
      };
    }

    created() {
      let retired_on: string | null = null;
      if (this.employee.retired_on) {
        const retired_on_obj = new Date(this.employee.retired_on);
        retired_on_obj.setDate(retired_on_obj.getDate() + 1);
        retired_on = util.formatDate(retired_on_obj, "yyyy-mm-dd");
      }
      this.m.setLostSocialIns({
        employee_id: this.employee.id,
        data: {
          social_ins_lost_on: retired_on,
          employee_lost_reason: 4,
          employee_lost_reason_date: this.employee.retired_on,
          employee_is_double_biz: false,
          employee_is_retire: false,
          employee_biko_other: null,
          employee_card: 1,
          employee_no_card: null,
          employee_over_seventy: false,
          employee_over_seventy_date: null,
        },
      });
    }

    get tmp_social_ins_lost() {
      if (this.m.social_ins_lost[this.employee.id]) {
        return this.m.social_ins_lost[this.employee.id];
      } else {
        return {};
      }
    }
    set tmp_social_ins_lost(data) {
      this.m.setLostSocialIns({ employee_id: this.employee.id, data });
    }
  }
