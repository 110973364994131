import { Component, Vue, Mixins } from "vue-property-decorator";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/aggregation/manager";
import global from "@/vuex/aggregation/global";

@Component
export default class mixinAggregation extends Mixins(utilMixins) {
  get m() {
    return manager;
  }

  get is_set_global(): boolean {
    return global.is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }

  async get_global() {
    await global.fetch();
  }
  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data;
  }
}
