
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import dialog from "@/vuex/dialog";
  import mixinDocument from "@/mixins/mixinDocument";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonTab from "@/components/common/Tab.vue";
  import DocumentMain from "@/components/document/Main.vue";
  import AggregationMain from "@/components/aggregation/Main.vue";

  @Component({
    components: {
      CommonProgress,
      CommonTab,
      DocumentMain,
      AggregationMain,
    },
  })
  export default class Document extends Mixins(mixinDocument) {
    view_waiting() {
      this.change_page("document_waiting");
    }
  }
