
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import dialog from "@/vuex/dialog";
import DocumentHistoryemployeelyRow from "@/components/document/Historyemployeely/Row.vue";

@Component({
  components: {
    DocumentHistoryemployeelyRow,
  }
})
export default class Table extends Mixins(mixinDocument) {
}
