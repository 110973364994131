
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonCheck from "@/components/common/Check.vue";
import CommonDate from "@/components/common/Date.vue";
import DocumentSocialinsdependantSpoucerow from "@/components/document/Socialinsdependant/Spoucerow.vue";
import DocumentSocialinsdependantDependantrow from "@/components/document/Socialinsdependant/Dependantrow.vue";

@Component({
  components: {
    CommonCheck,
    CommonDate,
    DocumentSocialinsdependantSpoucerow,
    DocumentSocialinsdependantDependantrow,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() employee;

  get spouce() {
    return this.spouce_hash[this.employee.id];
  }
  get dependants() {
    return this.dependants_hash[this.employee.id] ?? [];
  }

  get dependant_index() {
    var array = [];
    var index = 0;
    this.dependants.forEach(dependant => {
      if (index != 0) array.push(index);
      index++;
    });
    return array;
  }

  get max_row() {
    return Math.max(1, this.dependants.length);
  }

  created() {
    this.m.setDependantSocialIns({employee_id: this.employee.id, data: {
      is_pres_confirm: false,
      social_ins_got_on: this.employee.social_ins_got_on,
      spouce: null,
      dependants: [],
    }});

    if (this.spouce) {
      Vue.set(this.tmp_social_ins_dependant, 'spouce', {
        type: null,
        is_dependant: true,
        pension_ins_id: null,
        sex: this.spouce.sex == 0 ? 1 : 2,
        country: null,
        abroad_kana: null,
        abroad_name: null,
        zip: this.spouce.is_housemate == 1 ? this.employee.zip : null,
        address: this.spouce.is_housemate == 1 ? this.employee.address: null,
        tel_type: 1,
        tel: this.spouce.is_housemate == 1 ? this.employee.tel: null,
        got_on: null,
        get_reason: null,
        get_reason_other: null,
        job: null,
        job_other: null,
        not_got_on: null,
        not_get_died_on: null,
        not_get_reason: null,
        not_get_reason_other: null,
        is_abroad_special: null,
        abroad_special_reason: null,
        abroad_special_reason_other: null,
        abroad_special_date: null,
        abroad_not_special_reason: null,
        abroad_not_special_reason_other: null,
        abroad_not_special_date: null,
        abroad_not_special_reason_date: null,
        biko: null,
        is_confirm: false,
      });
    }
    
    this.dependants.forEach(dependant => {
      this.tmp_social_ins_dependant.dependants.push({
        dependant_id: dependant.id,
        type: null,
        relationship: '01',
        relationship_other: null,
        zip: dependant.is_housemate == 1 ? this.employee.zip : null,
        address: dependant.is_housemate == 1 ? this.employee.address: null,
        is_abroad_special: null,
        abroad_special_reason: null,
        abroad_special_reason_other: null,
        abroad_special_date: null,
        abroad_not_special_date: null,
        abroad_not_special_reason: null,
        abroad_not_special_reason_other: null,
        abroad_not_special_reason_date: null,
        got_on: null,
        get_reason: null,
        get_reason_other: null,        
        not_got_on: null,
        not_get_reason: null,
        not_get_reason_other: null,
        job: null,
        biko: null,
        is_confirm: false,
      });
    })
  }

  get tmp_social_ins_dependant() {
    if (this.m.social_ins_dependant[this.employee.id]) {
      return this.m.social_ins_dependant[this.employee.id];
    } else {
      return {};
    }
  }
  set tmp_social_ins_dependant(data) {
    this.m.setDependantSocialDependant({employee_id: this.employee.id, data});
  }
}
