
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import $ from "jquery";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonSelect,
  }
})
export default class Pager extends Vue {
  @Prop() value: string | null;
  @Prop() error_cond: [{func, msg}];
  @Prop({default: '年'}) year_label;
  @Prop({default: '15px'}) year_label_width: string;
  @Prop({default: '121px'}) max_width: string;

  @Prop() max_year: number;
  @Prop({default: 1930}) min_year: number;
  @Prop({default: 0}) plus_max_year: number;
  @Prop() minus_min_year: number;
  @Prop() default_year: number;

  once_input: boolean = false;//lazy

  year: number | string = '';

  no_watch: boolean = false;
  options = {};

  created() {
    const date = new Date();
    var max_year = this.max_year;
    if (max_year === undefined) {
      const date = new Date();
      max_year = Number(util.formatDate(date, 'yyyy'));
      max_year += this.plus_max_year;
    }

    var min_year = this.min_year;
    if (this.minus_min_year !== undefined) {
      const date = new Date();
      min_year = Number(util.formatDate(date, 'yyyy'));
      min_year -= this.minus_min_year;
    }

    this.options = {};
    for(var i = max_year; i >= min_year; i--) {
      this.options[i] = util.year2nengo(i);
    }
    this.set_date(this.value);
    this.emit_date();
  }

  @Watch('value')
  on_change_value(value) {
    if (!this.no_watch)
      this.set_date(value);
  }

  set_date(value) {
    const date = new Date();

    if (value === null) {
      this.year = null
    } else {
      this.year = value;
    }
    

    if (this.year === null) {
      this.year = Number(util.formatDate(date, 'yyyy'));
    }
    if (this.default_year) {
      this.year = this.default_year;
    }
  }

  emit_date() {
    if (this.year != '' && this.year != null) {
      let date = this.year;
      if (this.value != date) {
        this.no_watch = true;
      }
      this.$emit('input', date);
    } else {
      if (this.value != null) {
        this.no_watch = true;
      }
      this.$emit('input', null);
    }
  }

  get styles () {
    return {
      '--yearlabelwidth': this.year_label_width,
      '--datemaxwidth': this.max_width,
    }
  }

  input_year(e) {
    var val = e.target.value;

    if (val == 'null') {
      val = null;
    }

    this.year = val;
    this.emit_date();
  }


  prev_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    const prev_element = <HTMLInputElement>$('.textbox_focus')[index - 1];
    if (prev_element) {
      if (prev_element.type == 'text' || prev_element.type == 'url' || prev_element.type == 'tel') {
        prev_element.selectionStart = 0;
        prev_element.selectionEnd = prev_element.value.length;
        prev_element.focus();
      }
    }
  }
  next_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    const next_element = <HTMLInputElement>$('.textbox_focus')[index + 1];
    if (next_element) {
      if (next_element.type == 'text' || next_element.type == 'url' || next_element.type == 'tel') {
        next_element.selectionStart = 0;
        next_element.selectionEnd = next_element.value.length;
        next_element.focus();
      }
    }
  }
}
