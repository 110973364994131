
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonCheck from "@/components/common/Check.vue";

  @Component({
    components: {
      CommonCheck,
    },
  })
  export default class Row extends Mixins(mixinDocument) {
    @Prop() employee;
    @Prop() is_invalid_row?: boolean;
    @Prop() is_valid: boolean;
    @Prop() no_gray: boolean;
    @Prop() is_retired: boolean;

    get tr_class() {
      if (this.no_gray) {
        return [
          this.is_invalid_row && !this.is_valid ? "invalid" : "",
          this.tmp_selected_employee ? "selected" : "",
        ];
      } else if (this.is_retired) {
        return [
          this.employee.is_now_retire ? "" : "other_branch",
          this.is_invalid_row && !this.is_valid ? "invalid" : "",
          this.tmp_selected_employee ? "selected" : "",
        ];
      } else {
        return [
          this.employee.is_now_retire ? "other_branch" : "",
          this.is_invalid_row && !this.is_valid ? "invalid" : "",
          this.tmp_selected_employee ? "selected" : "",
        ];
      }
    }

    get tmp_selected_employee() {
      return this.m.selected_employee.indexOf(this.employee.id) !== -1;
    }
    set tmp_selected_employee(val) {
      if (this.is_multi_employee) {
        this.m.setSelectedEmployeeMulti(this.employee.id);
      } else {
        this.m.setSelectedEmployeeSingle(this.employee.id);
      }
    }
  }
