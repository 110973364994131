
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonSelect from "@/components/common/Select.vue";
import CommonCheck from "@/components/common/Check.vue";
import CommonText from "@/components/common/Text.vue";
import CommonZip from "@/components/common/Zip.vue";
import CommonDate from "@/components/common/Date.vue";

@Component({
  components: {
    CommonSelect,
    CommonCheck,
    CommonText,
    CommonZip,
    CommonDate,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() employee;
  @Prop() spouce;
  @Prop() max_row;

  get options() {
    return {
      spouce_type: {1: '該当', 2: '非該当', 3: '変更'},
      spouce_sex: {1: '夫', 2: '妻', 3: '夫(未届)', 4: '妻(未届)'},
      spouce_tel_type: {1: '自宅', 2: '携帯', 3: '勤務先', 4: 'その他'},
      spouce_get_reason: {1: '配偶者の就職', 2: '婚姻', 3: '離婚', 4: '収入減少', 5: 'その他'},
      spouce_not_get_reason: {1: '死亡', 2: '離婚', 3: '就職・収入増加', 4: '75歳到達', 5: '障害認定', 6: 'その他'},
      spouce_job: {1: '無職', 2: 'パート', 3: '年金受給者', 4: 'その他'},
      spouce_is_abroad_special: {1: '該当', 2: '非該当'},
      spouce_abroad_special_reason: {1: '留学', 2: '同行家族', 3: '特定活動', 4: '海外婚姻', 5: 'その他'},
      spouce_abroad_not_special_reason: {1: '国内転入', 2: 'その他'},
    };
  }


  get tmp_social_ins_dependant() {
    if (this.m.social_ins_dependant[this.employee.id]) {
      return this.m.social_ins_dependant[this.employee.id];
    } else {
      return {};
    }
  }
  set tmp_social_ins_dependant(data) {
    this.m.setDependantSocialIns({employee_id: this.employee.id, data});
  }
}
