
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Row extends Mixins(mixinDocument) {
  @Prop() tmp_contract_document;

  view_contract_document() {
    this.change_page('contract_document', {contract_document_id: this.tmp_contract_document.id});
  }
}
