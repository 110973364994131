
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import DocumentEmpinsgetRow from "@/components/document/Empinsget/Row.vue";

@Component({
  components: {
    CommonInfobutton,
    DocumentEmpinsgetRow,
  }
})
export default class Table extends Mixins(mixinDocument) {
}
