
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import DocumentEmployeeBranchselector from "@/components/document/Employee/Branchselector.vue";
import DocumentEmployeePagerHead from "@/components/document/Employee/pager/Head.vue";

@Component({
  components: {
    DocumentEmployeeBranchselector,
    DocumentEmployeePagerHead,
  }
})
export default class Control extends Mixins(mixinDocument) {
  
}
