
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";

  @Component({
    components: {},
  })
  export default class Main extends Mixins(mixinDocument) {
    get viewing_document() {
      if (this.s_auth == 0) {
        const gensentax = this.global.auth_gensentax_employee == 1;
        return [
          true,
          false,
          true,
          gensentax,
          false,
          false,
          false,
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];
      } else if (this.s_auth == 1) {
        return [
          true,
          false,
          true,
          true,
          true,
          false,
          true,
          true,
          true,
          false,
          true,
          true,
          true,
          false,
          false,
          true,
          false,
          false,
          true,
          true,
          true,
          true,
          true,
          true,
          false,
          true,
          false,
        ];
      } else if (this.s_auth == 2) {
        return [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
      }
    }

    selectDocument(num) {
      this.m.setSelectedDocument(num);
      if (num == 26) {
        this.m.setSelectedYear(2024);
      }
      this.scrollBottom();
    }

    scrollBottom() {
      console.log("scool");
      $("html, body").animate(
        {
          scrollTop: 100000,
        },
        0,
      );
    }

    goto_add_template_page() {
      this.change_page("document_template", { document_template_id: 0 });
    }
    goto_laborins_page() {
      this.change_page("laborins");
    }
    goto_renew_stdincome_page() {
      this.change_page("renew_stdincome");
    }
    goto_update_stdincome_page() {
      this.change_page("update_stdincome");
    }
    /*
    0: '給与明細書',
    1: '所得税徴収高計算書',
    2: '賞与明細書',
    3: '源泉徴収票(受給者用)',
    4: '源泉徴収票(税務署提出用)',
    5: '雇用保険被保険者離職票',
    6: '賃金台帳',
    7: '扶養控除等申告書',
    8: '配偶者控除等申告書',
    9: '保険料控除申告書',
    10: '住宅借入金等特別控除申告書',
    11: '源泉徴収簿',
    12: '労働者名簿',
    13: '給与支払報告書',
    14: '法定調書合計表',
    15: '雇用契約書',
    16: '賞与支払届',
    17: '基・配・所控除申告書',
    18: '社会保険被保険者資格取得届',
    19: '雇用保険被保険者資格取得届',
    20: '被扶養者(異動)届/第3号被保険者関係届',
    21: '社会保険被保険者資格喪失届',
    22: '雇用保険被保険者資格喪失届',
    */
  }
