
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonZip from "@/components/common/Zip.vue";
  import CommonYearmonth from "@/components/common/Yearmonth.vue";
  import CommonDate from "@/components/common/Date.vue";
  import CommonRadio from "@/components/common/Radio.vue";
  import CommonYear from "@/components/common/Year.vue";
  import CommonCheck from "@/components/common/Check.vue";

  @Component({
    components: {
      CommonSelect,
      CommonText,
      CommonZip,
      CommonYearmonth,
      CommonDate,
      CommonRadio,
      CommonYear,
      CommonCheck,
    },
  })
  export default class Row extends Mixins(mixinDocument) {
    @Prop() employee;

    get options() {
      return {
        nendo_type: { 1: "現年度", 2: "新年度", 3: "両年度" },
        transfer_reason: {
          1: "退職",
          2: "転職",
          3: "休職・長欠",
          5: "死亡",
          6: "支払少額・不定期",
          7: "その他",
        },
        after_levy_way: { 3: "普通徴収", 2: "一括徴収", 1: "特別徴収継続" },
        bundle_levy_reason: {
          1: "異動が12月31日までで、一括徴収の申出があったため",
          2: "異動が1月1日以降で、特別徴収の継続の申出がないため",
        },
        regular_levy_reason: {
          1: "異動が12月31日までで、一括徴収の申出がないため",
          2: "5月31日までに支払われるべき給与又は退職手当等の額が未徴収税額以下であるため",
          3: "死亡による退職であるため",
        },
        new_need_document: { 1: "必要", 2: "不要" },
      };
    }

    created() {
      this.m.setTransfer({
        employee_id: this.employee.id,
        data: {
          nendo_type: 1,
          company_specified_num: "",
          company_destination_num: "",
          employee_zip: this.employee.resident_tax_years[this.m.selected_year] ?? null,
          employee_address:
            (this.employee.resident_tax_years[this.m.selected_year] ?? null) == this.employee.zip
              ? this.employee.address
              : null,
          employee_after_zip: this.employee.zip,
          employee_after_address: this.employee.address,
          restax_year_money: this.employee.resident_tax_yearly_money,
          already_levy_start_yearmonth: null,
          already_levy_end_yearmonth: null,
          already_levy_money: this.employee.resident_tax_paid_money,
          yet_levy_start_yearmonth: null,
          yet_levy_end_yearmonth: null,
          transfered_on: util.today(),
          transfer_reason: 1,
          transfer_reason_other: null,
          after_levy_way: 3,
          regular_levy_reason: 1,
          regular_levy_nendo_1: util.today_obj().year,
          regular_levy_nendo_2: util.today_obj().year,
          bundle_levy_reason: 1,
          bundle_levy_nendo: util.today_obj().year,
          bundle_levy_nendo_1: util.today_obj().year,
          bundle_levy_nendo_2: util.today_obj().year,
          bundle_levy_date: null,
          bundle_pay_month: null,
          pay_sum: this.employee.pay_sum,
          socialins_sum: this.employee.socialins_sum,

          new_company_is_new: true,
          new_company_specified_num: null,
          new_company_zip: null,
          new_company_address: null,
          new_company_kana: null,
          new_company_name: null,
          new_company_post: null,
          new_company_staff_name: null,
          new_company_staff_tel: null,
          new_company_monthly_money: null,
          new_company_pay_start_month: null,
          new_need_document: 1,
        },
      });
    }

    get yet_levy_money() {
      if (
        isFinite(this.tmp_transfer.restax_year_money) &&
        isFinite(this.tmp_transfer.already_levy_money)
      ) {
        return this.tmp_transfer.restax_year_money - this.tmp_transfer.already_levy_money;
      } else {
        return "";
      }
    }

    get tmp_transfer() {
      if (this.m.transfer[this.employee.id]) {
        return this.m.transfer[this.employee.id];
      } else {
        return {};
      }
    }
    set tmp_transfer(data) {
      this.m.setTransfer({ employee_id: this.employee.id, data });
    }
  }
