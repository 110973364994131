
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import CommonInfobutton from "@/components/common/Infobutton.vue";

@Component({
  components: {
    CommonInfobutton,
  }
})
export default class Main extends Mixins(mixinDocument) {
  get is_gensentax_document() {
    return this.m.selected_document == 3 || this.m.selected_document == 4;
  }
  download() {
    if (this.m.selected_document > 100) {
      var document_template_id = this.m.selected_document - 100;
      this.m.download({document_template_id: document_template_id, document_template_name: this.document_template_hash[document_template_id].name});
    } else {
      this.m.download({});
    }
  }

  get tmp_media_code() {
    return this.m.media_code;
  }
  set tmp_media_code(val) {
    this.m.setMediaCode(val);
  }

    /*
    0: '給与明細書',
    1: '所得税徴収高計算書',
    2: '賞与明細書',
    3: '源泉徴収票(受給者用)',
    4: '源泉徴収票(税務署提出用)',
    5: '雇用保険被保険者離職票',
    6: '賃金台帳',
    7: '扶養控除等申告書',
    8: '配偶者控除等申告書',
    9: '保険料控除申告書',
    10: '住宅借入金等特別控除申告書',
    11: '源泉徴収簿',
    12: '労働者名簿',
    13: '給与支払報告書',
    14: '法定調書合計表',
    15: '雇用契約書',
    */
}
