
  import { Component, Vue, Emit, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import mixinDocument from "@/mixins/mixinDocument";
  import global from "@/vuex/document/global";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonSelect,
    },
  })
  export default class Branchselector extends Mixins(mixinDocument) {}
