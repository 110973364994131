
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDocument from "@/mixins/mixinDocument";
  import DocumentDocument from "@/components/document/Document.vue";
  import DocumentDate from "@/components/document/Date.vue";
  import DocumentYearmonth from "@/components/document/Yearmonth.vue";
  import DocumentSpan from "@/components/document/Span.vue";
  import DocumentYear from "@/components/document/Year.vue";
  import DocumentCustomtype from "@/components/document/Customtype.vue";
  import DocumentEgov from "@/components/document/Egov.vue";
  import DocumentEltax from "@/components/document/Eltax.vue";
  import DocumentReport from "@/components/document/Report.vue";
  import DocumentSiku from "@/components/document/Siku.vue";
  import DocumentUnifiednum from "@/components/document/Unifiednum.vue";
  import DocumentEmployeeretired from "@/components/document/Employeeretired.vue";
  import DocumentEmployeemonthly from "@/components/document/Employeemonthly.vue";
  import DocumentEmployeeyearly from "@/components/document/Employeeyearly.vue";
  import DocumentEmployeebonuspay from "@/components/document/Employeebonuspay.vue";
  import DocumentTableorder from "@/components/document/Tableorder.vue";
  import DocumentPaymenttable from "@/components/document/Paymenttable.vue";
  import DocumentPaymenttableetax from "@/components/document/Paymenttableetax.vue";
  import DocumentLegaltableetax from "@/components/document/Legaltableetax.vue";
  import DocumentBulketax from "@/components/document/Bulketax.vue";
  import DocumentLegaltable from "@/components/document/Legaltable.vue";
  import DocumentEmployeeempinsget from "@/components/document/Employeeempinsget.vue";
  import DocumentEmployeedependant from "@/components/document/Employeedependant.vue";
  import DocumentEmployeerestax from "@/components/document/Employeerestax.vue";
  import DocumentEmpinsget from "@/components/document/Empinsget.vue";
  import DocumentEmpinslost from "@/components/document/Empinslost.vue";
  import DocumentSocialinsget from "@/components/document/Socialinsget.vue";
  import DocumentSocialinsdependant from "@/components/document/Socialinsdependant.vue";
  import DocumentSocialinslost from "@/components/document/Socialinslost.vue";
  import DocumentBonuspay from "@/components/document/Bonuspay.vue";
  import DocumentLabor from "@/components/document/Labor.vue";
  import DocumentTransfer from "@/components/document/Transfer.vue";
  import DocumentPdf from "@/components/document/Pdf.vue";
  import DocumentPaidhol from "@/components/document/Paidhol.vue";
  import DocumentCsv from "@/components/document/Csv.vue";
  import DocumentCsvpdf from "@/components/document/Csvpdf.vue";
  import DocumentInsgetcsv from "@/components/document/Insgetcsv.vue";
  import DocumentSend from "@/components/document/Send.vue";
  import DocumentHistory from "@/components/document/History.vue";
  import DocumentHistoryemployeely from "@/components/document/Historyemployeely.vue";

  @Component({
    components: {
      DocumentDocument,
      DocumentDate,
      DocumentYearmonth,
      DocumentSpan,
      DocumentYear,
      DocumentCustomtype,
      DocumentEgov,
      DocumentEltax,
      DocumentReport,
      DocumentSiku,
      DocumentUnifiednum,
      DocumentEmployeeretired,
      DocumentEmployeemonthly,
      DocumentEmployeeyearly,
      DocumentEmployeebonuspay,
      DocumentTableorder,
      DocumentPaymenttable,
      DocumentPaymenttableetax,
      DocumentLegaltableetax,
      DocumentBulketax,
      DocumentLegaltable,
      DocumentEmployeeempinsget,
      DocumentEmployeedependant,
      DocumentEmployeerestax,
      DocumentEmpinsget,
      DocumentEmpinslost,
      DocumentSocialinsget,
      DocumentSocialinsdependant,
      DocumentSocialinslost,
      DocumentBonuspay,
      DocumentLabor,
      DocumentTransfer,
      DocumentPdf,
      DocumentPaidhol,
      DocumentCsv,
      DocumentCsvpdf,
      DocumentInsgetcsv,
      DocumentSend,
      DocumentHistory,
      DocumentHistoryemployeely,
    },
  })
  export default class Main extends Mixins(mixinDocument) {
    created() {
      this.m.resetAll();
    }
  }
