
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import CommonProgress from "@/components/common/Progress.vue";
import CommonText from "@/components/common/Text.vue";
import CommonCheck from "@/components/common/Check.vue";

@Component({
  components: {
    CommonProgress,
    CommonText,
    CommonCheck,
  }
})
export default class Main extends Mixins(mixinDocument) {
  get sikus() {
    this.m.resetUnifiedNum();
    Object.keys(this.siku_yearly.sikus).forEach(name => {
      if (name != '不明') {
        this.m.setUnifiedNumValue({name, val: null});
        this.m.setUnifiedNumInvalid({name, val: false});
      }
    });
    return this.siku_yearly.sikus;
  }
    /*
    0: '給与明細書',
    1: '所得税徴収高計算書',
    2: '賞与明細書',
    3: '源泉徴収票(受給者用)',
    4: '源泉徴収票(税務署提出用)',
    5: '雇用保険被保険者離職票',
    6: '賃金台帳',
    7: '扶養控除等申告書',
    8: '配偶者控除等申告書',
    9: '保険料控除申告書',
    10: '住宅借入金等特別控除申告書',
    11: '源泉徴収簿',
    12: '労働者名簿',
    13: '給与支払報告書',
    14: '法定調書合計表',
    15: '雇用契約書',
    */
}
